import React, { useState } from 'react';
import Header from './components/Header';
import Inicio from './components/Inicio';
import Formulario from './components/Formulario';
import Qr from './components/Qr';

const App = () => {
  const [qrImage, setQrImage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleQrImageUpdate = (image) => {
    setQrImage(image);
    setFormSubmitted(true); // Marca el formulario como enviado
  };

  return (
<div className="relative min-h-screen overflow-hidden">
  <div className="absolute inset-0 bg-center bg-cover bg-fixed" style={{ backgroundImage: 'url(/bg.webp)' }}></div>
  <div className="relative z-10">
    <Header />
    <div className="pt-14 md:pt-20">
      <Inicio />
    </div>
    <div className="flex justify-center items-center  pb-10 px-6">
      {!formSubmitted ? (
        <Formulario onQrImageUpdate={handleQrImageUpdate} />
      ) : (
        <Qr qrImage={qrImage} />
      )}
    </div>
  </div>
</div>

  );
};

export default App;
