import React from 'react';

const Header = () => {
  return (
    <header className="bg-dark-blue-opacity-60 text-white p-5 w-full fixed top-0 left-0 z-50">
      <div className="container mx-auto flex items-center justify-between">
        <img
          src="../Logo.png"
          alt="Logo"
          className="h-13"
        />
      </div>
    </header>
  );
}

export default Header;
