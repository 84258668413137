import React, { useState } from 'react';
import axios from 'axios';

const Formulario = ({ onQrImageUpdate }) => {
  const [datosFormulario, setDatosFormulario] = useState({
    nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    correo: '',
    prensa: 0, // Nuevo estado para el checkbox
    zona: '', // Nuevo estado para el selector
  });
  const [error, setError] = useState('');
  const [cargando, setCargando] = useState(false); // Nuevo estado para el spinner

  const manejarCambio = (e) => {
    const { name, value, type, checked } = e.target;
    setDatosFormulario(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const manejarEnvio = async (e) => {
    e.preventDefault();
    setCargando(true); // Inicia el spinner

    try {
      const respuesta = await axios.post('https://pmr2024.com/php/registro.php/', datosFormulario);
      if (respuesta.data.status === 'error') {
        setError(respuesta.data.message);
      } else {
        setError('');
        onQrImageUpdate(respuesta.data.qrImage);
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : 'Error inesperado. Por favor, inténtalo de nuevo.');
    } finally {
      setCargando(false); // Detiene el spinner después de la respuesta
    }
  };

  return (
    <div className="relative flex flex-col md:flex-row items-stretch w-full max-w-screen-lg mx-auto p-0 bg-dark-blue-opacity-60 overflow-visible">
      <div className="absolute top-0 left-0 transform -translate-x-1/4 -translate-y-1/4 z-0 hidden md:block">
        <img
          src="/form.png"
          alt="Descripción de la imagen"
          className="w-[120%] h-auto object-cover"
        />
      </div>

      <div className="relative z-0 block md:hidden w-full">
        <img
          src="/form.png"
          alt="Descripción de la imagen"
          className="w-full h-auto object-cover"
        />
      </div>

      <div className="relative z-10 flex-1 p-6 flex items-center justify-center rounded-lg shadow-md md:ml-[55%] mt-8 md:mt-0">
        <form className="flex flex-col gap-4 w-4/5 max-w-lg" onSubmit={manejarEnvio}>
          <h2 className='text-4xl lg:text-5xl font-sans uppercase text-[#f4bf89] mt-0 md:mt-10 mb-6'>Regístrate</h2>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={datosFormulario.nombre}
                onChange={manejarCambio}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
                placeholder='Nombre'
              />
            </div>
            <div>
              <input
                type="text"
                id="primer_apellido"
                name="primer_apellido"
                value={datosFormulario.primer_apellido}
                onChange={manejarCambio}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
                placeholder='Primer Apellido'
              />
            </div>
            <div>
              <input
                type="text"
                id="segundo_apellido"
                name="segundo_apellido"
                value={datosFormulario.segundo_apellido}
                onChange={manejarCambio}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
                placeholder='Segundo Apellido'
              />
            </div>
            <div>
              <input
                type="email"
                id="correo"
                name="correo"
                value={datosFormulario.correo}
                onChange={manejarCambio}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                required
                placeholder='Correo Electrónico'
              />
            </div>

            {/* Nuevo selector de colores */}
            <div>
              <select
                id="zona"
                name="zona"
                value={datosFormulario.zona}
                onChange={manejarCambio}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              >
                <option className='text-center' value="">---------- Seleccionar zona ----------</option>
                <option value="Morado">Morado</option>
                <option value="Verde">Verde</option>
                <option value="Naranja">Naranja</option>
                <option value="Blanco">Blanco</option>
                <option value="Negro">Negro</option>
                <option value="Dorado">Dorado</option>
              </select>
            </div>
            
            <div className="flex items-center">
              <input
                type="checkbox"
                id="prensa"
                name="prensa"
                checked={datosFormulario.prensa}
                onChange={manejarCambio}
                className="mr-3"
              />
              <label htmlFor="prensa" className="text-white">Soy prensa</label>
            </div>

            {error && <div className="mt-4 text-red-600">{error}</div>}
          </div>

          <div className="flex justify-center md:justify-end mt-4">
            <button
              type="submit"
              className="w-2/3 bg-[#f4bf89] font-bold text-white mb-10 md:mb-6 py-4 px-2 rounded-xl shadow-lg hover:bg-[#e3a67f]"
              disabled={cargando} // Deshabilita el botón mientras carga
            >
              {cargando ? (
                <div className="flex justify-center items-center">
                  <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0v0"></path>
                  </svg>
                  Enviando formulario...
                </div>
              ) : (
                'Registrarme'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Formulario;