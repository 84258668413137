import React from 'react';


const Inicio = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <img
        src="/OB_1.webp"
        alt="Descripción de la imagen"
        className="w-11/12 max-w-4xl mb-4 shadow-lg" // Ajusta el tamaño de la imagen
      />
      <p className="text-2xl font-sans text-white w-full text-center mt-16 md:mt-12">
      ¡Felicidades! Eres uno de los invitados al evento Premio al
      </p>
      <p className="text-2xl font-sans text-white w-full text-center">
      Mérito Restaurantero, a continuación pedimos nos ayudes
      </p>
      <p className="text-2xl font-sans text-white w-full text-center">
      a realizar tu registro.
      </p>
      <p className="text-2xl font-sans font-bold text-white w-full text-center mt-6">
      Recuerda que el registro es personal e intransferible.
      </p>
    </div>
  );
}

export default Inicio;
