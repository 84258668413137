import React from 'react';

const Qr = ({ qrImage }) => {
  const qrImageSrc = `data:image/png;base64,${qrImage}`;

  return (
    <div className="flex flex-col items-center justify-center p-6">
      <div className="flex items-center justify-center w-full max-w-4xl">
        <div className="text-center">
          <img 
            src={qrImageSrc} 
            alt="Código QR" 
            className="mx-auto mb-4" 
            style={{ width: 'auto', height: 'auto', maxWidth: '500px', maxHeight: '500px' }}
          />
          <a
            href={qrImageSrc}
            download="codigo-qr.png"
            className="bg-[#f4bf89] text-white font-bold py-4 px-8 rounded-xl shadow-lg hover:bg-[#e3a67f] text-2xl mt-4 inline-block"
          >
            Descargar
          </a>
        </div>
      </div>
    </div>
  );
};

export default Qr;
